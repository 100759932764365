import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { FeathersVuex } from "./feathers-client";

import loadPlugins from "./plugins";
import moment from "moment";


/* moment em pt-br */
moment.locale("pt-br");

const app = createApp(App);
app.config.productionTip = false;

app.use(store);
app.use(router);
app.use(FeathersVuex);
/* plugins e filtros */
loadPlugins(app);
// loadFilters(app);

app.mount("#app");
